import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BlockAmount, dispAddress, dispNum, dispTime, normalizeInputNumber } from '../../common';
import { doDepositCancel, doDepositLastTrans, doDepositRefresh, doDepositStart } from './depositSlice';

import { selectAccountData } from '../../app/web3Slice';
import { SmCopyToClipboard } from '../../components/elems';





export default function Deposit() {

	const accountData = useSelector(selectAccountData);


	const remoteTran = useSelector(state => state.web3Deposit.tran);
	const last_trans = useSelector(state => state.web3Deposit.last_trans);

	const [kind, setKind] = useState('BSC_BNB');

	const [inputValue, setInputValue] = useState(0);
	const [valueInKind, setValueInKind] = useState(0);
	const [sdbn3Value, setSdbn3Value] = useState(0);
	const [inputKind, _setInputKind] = useState('inKind'); // inKind | sdbn3

	const inKindInputRef = useRef();
	const sdbn3InputRef = useRef();



	const dispatch = useDispatch();

	const buy = () => {
		dispatch(doDepositStart({ kind, userValue: normalizeInputNumber(valueInKind) }));
	}

	useEffect(() => {
		dispatch(doDepositLastTrans());
	}, []);

	const [timer, setTimer] = useState(0);
	useEffect(() => {
		if (!remoteTran && timer) {
			clearInterval(timer);
			setTimer(0);
		} else if (remoteTran && !timer) {
			let t = setInterval(() => {
				dispatch(doDepositRefresh());
			}, 5000);
			setTimer(t);
			return () => {
				console.log('refresh cleaned up');
				clearInterval(timer);
			}
		}
	}, [remoteTran, timer])

	const [endTime, setEndTime] = useState(0);
	const [secsLeft, setSecsLeft] = useState(0);
	useEffect(() => {
		if (remoteTran) {
			const now = new Date().getTime();
			const et = remoteTran.endTime + now - remoteTran.nowTime;
			setEndTime(et);
			setSecsLeft((et - now) / 1000);
		} else
			setEndTime(0);

	}, [remoteTran])

	useEffect(() => {
		if (endTime < 0)
			return;
		const interval = setInterval(() => {
			const now = new Date().getTime();
			setSecsLeft((endTime - now) / 1000)
		}, 1000);
		return () => clearInterval(interval);
	}, [endTime]);


	// ------------------- ÁTVÁLTÁSOK -----------------

	const setInputKind = (kind) => {
		if (kind == 'inKind') {
			setInputValue(valueInKind.toFixed(4));
			window.requestAnimationFrame(() => {
				inKindInputRef.current.focus();
				inKindInputRef.current.select();
			})
		} else {
			setInputValue(sdbn3Value.toFixed(2));
			window.requestAnimationFrame(() => {
				sdbn3InputRef.current.focus();
				sdbn3InputRef.current.select();
			})
		}
		_setInputKind(kind);
	}


	useEffect(() => {
		let bnbPrice = 1;
		let fee = accountData.depositStableFee;
		if (kind === 'BSC_BNB') {
			bnbPrice = accountData.bnbPriceBNB;
			fee = accountData.depositCoinFee;
		}
		if (kind === 'ETH_ETH') {
			bnbPrice = accountData.bnbPriceETH;
			fee = accountData.depositCoinFee;
		}
		let val = normalizeInputNumber(inputValue);
		if (inputKind === 'inKind') {
			setValueInKind(val);
			setSdbn3Value(Math.floor(val * bnbPrice / 0.015 * (100 - fee) / 100));
		} else {
			setSdbn3Value(val);
			setValueInKind(val * 100 / (100 - fee) * 0.015 / bnbPrice);
		}



	}, [inputValue, inputKind, kind, accountData]);

	return (
		<div className="page">


			{!remoteTran ? <> {/* ------------------------ nincs élő tran ------------------------- */}
				<h1>Buy with deposit</h1>
				<div className="page-main">

					<div className="block block-value" >
						<span className="title  pb-1">Select network and currency</span>

						<select className="form-select value" value={kind} onChange={(e) => setKind(e.target.value)} >
							<optgroup label="Binance">
								<option value="BSC_BNB">Binance / BNB + {accountData.depositCoinFee}% fee</option>
								<option value="BSC_USDT">Binance / USDT + {accountData.depositStableFee}% fee</option>
								<option value="BSC_USDC">Binance / USDC + {accountData.depositStableFee}% fee</option>
							</optgroup>
							<optgroup label="Ethereum">
								<option value="ETH_ETH">Ethereum / ETH + {accountData.depositCoinFee}% fee</option>
								<option value="ETH_USDT">Ethereum / USDT + {accountData.depositStableFee}% fee</option>
								<option value="ETH_USDC">Ethereum / USDC + {accountData.depositStableFee}% fee</option>
							</optgroup>
						</select>
					</div>

					{inputKind === 'inKind' && <>
						<div className="block block-value">
							<span className="title">Enter {kind.split('_')[1]} value</span>
							<span className="value px-1">
								<input className="flat amount"
									ref={inKindInputRef}
									value={inputValue}
									onChange={(e) => setInputValue(e.currentTarget.value)}
								/>
								<span className="dim">{kind.split('_')[1]}&nbsp;</span>
								<button type="button"
									className={'btn btn-primary btn-sm'}
									onClick={() => buy()} >
									Buy
								</button>
							</span>
						</div>

						<div className="block block-value" onClick={() => setInputKind('sdbn3')}>
							<span className="title">Calculated SDBN3 value</span>
							<span className="value">
								<BlockAmount amount={sdbn3Value} frac={2} /><span className="dim">SDBN3</span>
							</span>
						</div>
					</>}

					{inputKind === 'sdbn3' && <>

						<div className="block block-value" onClick={() => setInputKind('inKind')}>
							<span className="title">Calculated {kind.split('_')[1]} value</span>
							<span className="value">
								<BlockAmount amount={valueInKind} frac={4} /><span className="dim">{kind.split('_')[1]}</span>
							</span>
						</div>

						<div className="block block-value">
							<span className="title">Enter SDBN3 value</span>
							<span className="value px-1">
								<input className="flat amount"
									ref={sdbn3InputRef}
									value={inputValue}
									onChange={(e) => setInputValue(e.currentTarget.value)}
								/>
								<span className="dim">SDBN3&nbsp;</span>
								<button type="button"
									className={'btn btn-primary btn-sm'}
									onClick={() => buy()} >
									Buy
								</button>
							</span>
						</div>

					</>}

					{/* ------------------ RECENT TRANSACTIONS ---------------------- */}
					{last_trans && last_trans.length > 0 && <>
						<hr className='mt-2' />
						<h2>Recent transactions</h2>

						<table className="list">
							<thead>
								<tr>
									<th>At</th>
									<th>Amount</th>
									<th>Result</th>
								</tr>
							</thead>
							<tbody>

								{last_trans.map((t, ix) => <tr key={ix}>
									<td>{dispTime(t.createdAt)}</td>
									<td>{dispNum(t.payedValue || t.calculatedValue, 4) + ' ' + t.kind.split('_')[1]}</td>
									<td>{t.finishMode}</td>
								</tr>)}
							</tbody>
						</table>

					</>}


				</div>

				<div className="block">
					<Link className="btn btn-secondary" to='/'>Home</Link>
				</div>

			</> : <> {/* ------------------------ VAN élő tran ------------------------- */}
				<h1>Waiting for transaction</h1>
				<div className="page-main">



					<div className="block block-value">
						<span className="title">Amount to send</span>
						<span className="value">
							<BlockAmount amount={remoteTran.userValue} /><span className="dim">{remoteTran.kind.split('_')[1]}</span>
						</span>
					</div>

					<div className="block block-value">
						<span className="title">Time left</span>
						<span className="value">
							{secsLeft > 60
								&& <><BlockAmount amount={Math.max(0, Math.floor(secsLeft / 60))} frac={0} /><span className="dim">min</span>&nbsp;</>}
							<BlockAmount amount={Math.max(0, secsLeft % 60)} frac={0} /><span className="dim">sec</span>

						</span>
					</div>

					<div className="block block-value">
						<div className="title">Target address</div>
						<span className="value">{dispAddress(remoteTran.targetAddress)}<span className="dim"></span>
							<SmCopyToClipboard text={remoteTran.targetAddress} />
						</span>
					</div>

					<div className="block">
						<QRCode value={remoteTran.targetAddress} />
					</div>
					<div className="block block-value">
						<button className="btn btn-primary" onClick={() => dispatch(doDepositCancel())}>Cancel</button>
					</div>
					{false && <div className="block block-value">
						<button className="btn btn-primary" onClick={() => dispatch(doDepositRefresh())}>Refresh</button>
					</div>}
				</div>

			</>}

		</div>
	)
}