import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, BlockDim, dispAddress, dispNum } from '../../common';
import { SmCopyToClipboard } from '../../components/elems';




export default function Wallet() {

	// ------- selectors -----------
	const walletAddress = useSelector((state) => state.web3.walletAddress);
	const accountData = useSelector(selectAccountData);
	const userData = useSelector(state => state.web3.userData);


	const dispatch = useDispatch();
	const refLink = 'https://token.sdbn3.com?parentReferral=' + userData?.smnetData?.self_encrypted_id;

	return (
		<div className="page">
			<h1>Wallet</h1>
			<div className="page-main">
				<div className="block block-value">
					<span className="title">Wallet address</span>
					<span className="value">{dispAddress(walletAddress)}<span className="dim"></span>
						<SmCopyToClipboard text={walletAddress} />
					</span>
				</div>

				<div className="block block-value">
					<span className="title">Token balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceToken} />
						<BlockDim dim="SDBN 3" />
					</span>
				</div>

				<div className="block block-value">
					<span className="title">Token Plus balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceTokenPlus} />
						<BlockDim dim="SDBN 3 PLUS" />
					</span>
				</div>



				<div className="block block-value">
					<span className="title">USDT balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceUSDT} />
						<span className="dim">USDT</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">Comission balance</span>
					<span className="value">
						<BlockAmount amount={accountData.comission} />
						<span className="dim">USDT</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">USDC balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceUSDC} />
						<span className="dim">USDC</span>
					</span>
					{/*<span className='calc'>approved: {dispNum(accountData.approvedUSDC)}</span>*/}
				</div>

				<div className="block block-value">
					<span className="title">BNB Balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceBNB} />
						<span className="dim">BNB</span>
					</span>
				</div>



				<hr />


				<div className="block block-value">
					<span className="title">Min buy limit</span>
					<span className="value">
						<BlockAmount amount={accountData.minBuyLimit} />
						<span className="dim">USDT</span>
					</span>
				</div>

				<hr />

				{userData.smnetData.self_encrypted_id && <>
					<div className="block block-value">
						<span className="title">Reference link</span>
						<span className="value" style={{fontSize:'.8em'}} >{refLink}
							<SmCopyToClipboard text={refLink} />
						</span>
					</div>

					<hr />

				</>}


				<div className="block block-value">
					<span className='calc'>isAuthorized: {accountData.isAuthorized ? 'yes' : 'no'}</span>
					<span className='calc'>parent: {dispAddress(accountData.parent)} <SmCopyToClipboard text={accountData.parent} /></span>
				</div>

				
				{/*<div className="block block-value">
					<span className='calc'>bnbWalletFee: {accountData.bnbWalletFee}</span>
					<span className='calc'>depositStableFee: {accountData.depositStableFee}</span>
					<span className='calc'>depositCoinFee: {accountData.depositCoinFee}</span>
					<span className='calc'>bnbPriceBNB: {accountData.bnbPriceBNB}</span>
					<span className='calc'>bnbPriceETH: {accountData.bnbPriceETH}</span>

				</div>*/}


			</div>
			<div className='block'>
				<Link to="/" className='btn btn-secondary'>Home</Link>
			</div>

		</div>
	)
};


