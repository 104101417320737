import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, normalizeInputNumber } from '../../common';
import { doBuyBNB } from './buySlice';




export default function BuyBNB() {

	const accountData = useSelector(selectAccountData);

	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState(0);

	const [sdbn3Value, setSdbn3Value] = useState(0);
	const [bnbValue, setBnbValue] = useState(0);

	const [inputKind, _setInputKind] = useState('sdbn3'); // sdbn3 | bnb

	const sdbn3InputRef = useRef();
	const bnbInputRef = useRef();

	const setInputKind = (kind) => {
		if (kind == 'sdbn3') {
			setInputValue(sdbn3Value.toFixed(2));
			window.requestAnimationFrame(() => {
				sdbn3InputRef.current.focus();
				sdbn3InputRef.current.select();
			})
		} else {
			setInputValue(bnbValue.toFixed(6));
			window.requestAnimationFrame(() => {
				bnbInputRef.current.focus();
				bnbInputRef.current.select();
			})
		}
		_setInputKind(kind);
	}

	useEffect(() => {
		let val = normalizeInputNumber(inputValue);
		if (inputKind === 'sdbn3') {
			setSdbn3Value(val);
			setBnbValue(val * 0.015 / accountData.bnbPriceBNB * 100 / (100 - accountData.bnbWalletFee));
		} else {
			setBnbValue(val);
			setSdbn3Value(val * (100 - accountData.bnbWalletFee) / 100 * accountData.bnbPriceBNB / 0.015)

		}
		/*let sdbn3Amount = normalizeInputNumber(inputValue);
		let bnbValue = sdbn3Amount * 0.015 /  accountData.bnbPriceBNB  * 100 / (100-accountData.bnbWalletFee)  ;
		setBnbValue(bnbValue)*/
	}, [inputValue, accountData.calcBnbPrice, inputKind]);

	return (
		<div className="page">
			<h1>Buy with BNB</h1>
			<div className="page-main">
				<div className="block block-value">
					<span className="title">BNB Balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceBNB} /><span className="dim">BNB</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">BNB Wallet Fee</span>
					<span className="value">
						<BlockAmount amount={accountData.bnbWalletFee} /><span className="dim">%</span>
					</span>
				</div>

				<div className="block block-value">
					<span className="title">BNB Price</span>
					<span className="value">
						<BlockAmount amount={accountData.bnbPriceBNB} /><span className="dim">BNB</span>
					</span>
				</div>

				{inputKind === 'sdbn3' && <>
					<div className="block block-value">
						<span className="title">Enter SDBN3 value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={sdbn3InputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">SDBN3&nbsp;</span>
							<button type="button"
								className={'btn btn-primary btn-sm'}
								disabled={accountData.balanceBNB < bnbValue}
								onClick={() => dispatch(doBuyBNB({ amount: sdbn3Value, accountData }))} >
								Buy
							</button>
						</span>
						
					</div>


					<div className="block block-value" onClick={() => setInputKind('bnb')}>
						<span className="title">Calculated BNB value</span>
						<span className="value">
							<BlockAmount amount={bnbValue} frac={6} /><span className="dim">BNB</span>
						</span>
					</div>
				</>}


				{inputKind === 'bnb' && <>
					<div className="block block-value" onClick={() => setInputKind('sdbn3')}>
						<span className="title">Calculated SDBN3 value</span>
						<span className="value">
							<BlockAmount amount={sdbn3Value} frac={2} /><span className="dim">SDBN</span>
						</span>
					</div>


					<div className="block block-value"  >
						<span className="title">Enter BNB value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={bnbInputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">BNB&nbsp;</span>
							<button type="button"
								className={'btn btn-primary btn-sm'}
								disabled={accountData.balanceBNB < bnbValue}
								onClick={() => dispatch(doBuyBNB({ amount: sdbn3Value, accountData }))} >
								Buy
							</button>
						</span>
						
					</div>
				</>}







			</div>
			<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>
		</div>
	)
};


