import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disconnect, addToken, doLogin, doLogout } from './web3Slice';
import { Link } from 'react-router-dom';
import { SmLink } from '../components/elems';
import { token_address, token_plus_address } from '../config/config';




export default function Home() {

	// ------- selectors -----------
	const userData = useSelector(state => state.web3.userData);


	const dispatch = useDispatch();

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}

	return (
		<div className="page">
			<h1>SDBN3 TOKEN</h1>
			<div className="page-main">

				{/*<div>{userData.smnetStatus}</div>
				<div>{JSON.stringify(userData.smnetData)}</div>*/}

				<br />
				<SmLink to="/wallet" icon="wallet">Wallet</SmLink>
				<SmLink to="/buy/bnb" icon="currency-exchange">Buy with BNB</SmLink>
				<SmLink to="/buy/usdt" icon="currency-exchange">Buy with USDT</SmLink>
				<SmLink to="/buy/usdc" icon="currency-exchange">Buy with USDC</SmLink>
				<SmLink to="/buy/comission" icon="currency-exchange">Buy with Comission</SmLink>
				<SmLink to="/deposit" icon="qr-code">Buy with deposit</SmLink>

				<hr />

				<div className="other-buttons">
					{!userData?.userId
						&& <button className='btn btn-primary' onClick={() => dispatch(doLogin())} >Login</button>}
					<button className='btn btn-secondary btn-black' onClick={() => dispatch(addToken({symbol:'SDBN3', address: token_address}))}  >Add SDBN3 to wallet</button>
					<button className='btn btn-secondary btn-black' onClick={() => dispatch(addToken({symbol:'SDBN3+', address: token_plus_address}))}  >Add SDBN3+ to wallet</button>
				</div>

				<hr />
				<div className="other-buttons">
					{!!userData?.userId && <button className='btn btn-primary' onClick={() => dispatch(doLogout())}  >Logout</button>}
					{/*<button className='btn btn-light' onClick={() => disconnectIt()}  > Disconnect wallet</button>*/}
				</div>



			</div>
		</div>
	)
};


