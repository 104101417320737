import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, normalizeInputNumber } from '../../common';
import { doBuyComission } from './buySlice';




export default function BuyComission() {

	const accountData = useSelector(selectAccountData);

	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState(0);
	const [sdbn3Value, setSdbn3Value] = useState(0);
	const [usdValue, setUsdValue] = useState(0);

	const [inputKind, _setInputKind] = useState('usd'); // sdbn3 | usd
	const sdbn3InputRef = useRef();
	const usdInputRef = useRef();

	const setInputKind = (kind) => {
		if (kind == 'usd') {
			setInputValue(usdValue.toFixed(2));
			window.requestAnimationFrame(() => {
				usdInputRef.current.focus();
				usdInputRef.current.select();
			})
		} else {
			setInputValue(sdbn3Value.toFixed(2));
			window.requestAnimationFrame(() => {
				sdbn3InputRef.current.focus();
				sdbn3InputRef.current.select();
			})
		}
		_setInputKind(kind);
	}


	useEffect(() => {
		let val = normalizeInputNumber(inputValue);
		if (inputKind === 'usd') {
			setUsdValue(val);
			setSdbn3Value(val * 0.9 / 0.015);
		} else {
			setSdbn3Value(val);
			setUsdValue(val * 0.015 / 0.9);
		}

	}, [inputValue])

	//

	return (
		<div className="page">
			<h1>Buy with Comission</h1>
			<div className="page-main">
				<div className="block block-value">
					<span className="title">Comission</span>
					<span className="value">
						<BlockAmount amount={accountData.comission} /><span className="dim">USD</span>
					</span>
				</div>

				{inputKind === 'usd' && <>
					<div className="block block-value">
						<span className="title">Enter USD value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={usdInputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">USD&nbsp;</span>
							<button type="button"
								className={'btn btn-primary btn-sm'}
								disabled={usdValue <= 0 || accountData.comission < usdValue}
								onClick={() => dispatch(doBuyComission(usdValue))} >
								Buy
							</button>
						</span>
					</div>

					<div className="block block-value" onClick={() => setInputKind('sdbn3')}>
						<span className="title">Calculated SDBN3 value</span>
						<span className="value">
							<BlockAmount amount={sdbn3Value} /><span className="dim">SDBN3</span>
						</span>
					</div>
				</>}

				{inputKind === 'sdbn3' && <>
					<div className="block block-value" onClick={() => setInputKind('usd')}>
						<span className="title">Calculated USD value</span>
						<span className="value">
							<BlockAmount amount={usdValue} /><span className="dim">USD</span>
						</span>
					</div>

					<div className="block block-value">
						<span className="title">Enter SDBN3 value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={sdbn3InputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">SDBN3&nbsp;</span>
							<button type="button"
								className={'btn btn-primary btn-sm'}
								disabled={usdValue <= 0 || accountData.comission < usdValue}
								onClick={() => dispatch(doBuyComission(usdValue))} >
								Buy
							</button>
						</span>
					</div>
				</>}

			</div>
			<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>
		</div>
	)
};


