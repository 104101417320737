import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, dispNum, normalizeInputNumber } from '../../common';
import { doApproveUSDC, doBuyUSDC } from './buySlice';




export default function BuyUSDC() {

	const accountData = useSelector(selectAccountData);


	const dispatch = useDispatch();

	const [inputValue, setInputValue] = useState(0);

	const [sdbn3Value, setSdbn3Value] = useState(0);
	const [usdcValue, setUsdcValue] = useState(0);

	const [inputKind, _setInputKind] = useState('sdbn3'); // sdbn3 | usdc

	const sdbn3InputRef = useRef();
	const usdcInputRef = useRef();


	const setInputKind = (kind) => {
		if (kind == 'sdbn3') {
			setInputValue(sdbn3Value.toFixed(2));
			window.requestAnimationFrame(() => {
				sdbn3InputRef.current.focus();
				sdbn3InputRef.current.select();
			})
		} else {
			setInputValue(usdcValue.toFixed(4));
			window.requestAnimationFrame(() => {
				usdcInputRef.current.focus();
				usdcInputRef.current.select();
			})
		}
		_setInputKind(kind);
	}

	useEffect(() => {
		let val = normalizeInputNumber(inputValue);
		if (inputKind === 'sdbn3') {
			setSdbn3Value(val);
			setUsdcValue(val * 0.015);
		} else {
			setUsdcValue(val);
			setSdbn3Value(val / 0.015)

		}
	}, [inputValue, inputKind]);



	return (
		<div className="page">
			<h1>Buy with USDC</h1>
			<div className="page-main">
				<div className="block block-value">
					<span className="title">USDC balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceUSDC} /><span className="dim">USDC</span>
					</span>
				</div>
				<div className="block block-value">
					<span className="title">Approved USDC amount</span>
					<span className="value">
						<BlockAmount amount={accountData.approvedUSDC} /><span className="dim">USDC</span>
					</span>
				</div>




				{inputKind === 'sdbn3' && <>
					<div className="block block-value">
						<span className="title">Enter SDBN3 value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={sdbn3InputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">SDBN3&nbsp;</span>
						</span>

					</div>


					<div className="block block-value" onClick={() => setInputKind('usdc')}>
						<span className="title">Calculated USDC value</span>
						<span className="value">
							<BlockAmount amount={usdcValue} frac={4} /><span className="dim">USDC</span>
						</span>
					</div>
				</>}


				{inputKind === 'usdc' && <>
					<div className="block block-value" onClick={() => setInputKind('sdbn3')}>
						<span className="title">Calculated SDBN3 value</span>
						<span className="value">
							<BlockAmount amount={sdbn3Value} frac={2} /><span className="dim">SDBN</span>
						</span>
					</div>


					<div className="block block-value"  >
						<span className="title">Enter USDC value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={usdcInputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">USDC&nbsp;</span>

						</span>

					</div>
				</>}




				<div className="block block-value">
					<span className="title">Buy SDBN3 with USDC</span>
					<span className="value px-1">
						<button type="button"
							className={'btn btn-light btn-sm'}
							onClick={() => dispatch(doApproveUSDC(normalizeInputNumber(usdcValue)))} >
							Approve
						</button>
						<button type="button"
							className={'btn btn-primary btn-sm'}
							onClick={() => dispatch(doBuyUSDC(normalizeInputNumber(usdcValue)))} >
							Buy
						</button>
					</span>
				</div>

			</div>
			<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>

		</div>
	)
};


