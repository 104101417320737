
//export const API_BASE = "http://localhost:25003/api";
export const API_BASE = "https://token.sdbn3.com:25003/api";




export const sale_address = "0xEDeD5EAf2E98B0B1c478956300a83e2004BD45A7";
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";
export const usdc_address = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
export const token_address = "0x96E2f167180768418E2D80EBa8213457f9985A3C";
export const token_plus_address = "0x3503C50B11bCBdD0dBD3bFd40648715BBE14aB12";


/*
// ------------------------------- PRODTEST ----------------------------------
export const sale_address = "0x9b178c83B50eb3F938E5BEC970eAE58F20113F3f";
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";
export const usdc_address = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
export const token_address = "0x39C545205cadb850b5e6a86e12a1EF5D98CfFE34";
export const token_plus_address = "0x865A80377A252AE77359a57a78D28FBAEe8145b7";
*/

/*
// ------------------------------- TEST ----------------------------------
export const CHAIN = {
	chainId: '0x61',
	chainName: 'Binance Smart Chain Testnet',
	nativeCurrency: {
		name: 'tBNB',
		symbol: 'tBNB',
		decimals: 18
	},
	rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545/'],
	blockExplorerUrls: ['https://testnet.bscscan.com/']
};
*/




// ------------------------------- PRODUCTION ----------------------------------

export const CHAIN = {
	chainId: '0x38',
	chainName: 'Binance Smart Chain',
	nativeCurrency: {
		name: 'Binance Coin',
		symbol: 'BNB',
		decimals: 18
	},
	rpcUrls: ['https://bsc-dataseed1.binance.org/'],
	blockExplorerUrls: ['https://bscscan.com/']
}
