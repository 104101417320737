import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessages from '../toastMessages';
import { connect, disconnect, doLogin, doLogout, errorClose } from './web3Slice';

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { dispAddress } from '../common';
import BuyBNB from '../features/buy/BuyBNB';
import BuyUSDC from '../features/buy/BuyUSDC';
import BuyUSDT from '../features/buy/BuyUSDT';
import Deposit from '../features/deposit/Deposit';
import Wallet from '../features/wallet/Wallet';
import Home from './Home';
import BuyComission from '../features/buy/BuyComission';





export default function App() {

	// ------- selectors -----------
	const status = useSelector((state) => state.web3.status);
	const statusText = useSelector((state) => state.web3.statusText);
	const userData = useSelector(state => state.web3.userData);
	const walletAddress = useSelector((state) => state.web3.walletAddress);
	const depositTran = useSelector((state) => state.web3Deposit.tran);
	const statusModalRef = useRef(null);

	const dispatch = useDispatch();
	const showSpinner = (['query', 'networksetup'].indexOf(status) > -1);

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}
	const [design, setDesign] = useState(true);


	useEffect(() => {
		if (userData.smnetStatus === 'redirect') {
			window.location.href = userData.smnetData;
		}

	}, [
		userData.smnetStatus, userData.smnetData
	]);


	return (
		<div className="page-container container" >
			<Modal
				show={status == 'error'}
				centered={true}
				onHide={() => dispatch(errorClose())}
			>
				<Modal.Header closeButton>
					<Modal.Title>Error</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: statusText }}></div>
				</Modal.Body>
				<Modal.Footer>
					{status == 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(connect())} >Try again</Button>}
					{status != 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(errorClose())} >Ok</Button>}
				</Modal.Footer>
			</Modal>

			<Modal
				show={showSpinner}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Body className='spinner-body' >
					<br /><br />
					<img src="/images/spinner_orange.png" />
					<br /><br />
					<div dangerouslySetInnerHTML={{ __html: status === 'connecting' ? 'connecting...' : statusText }}></div>
					<br /><br />
					<a className='btn btn-outline-secondary btn-sm' onClick={() => window.location.reload()}>Reload page</a>
				</Modal.Body>
			</Modal>

			{status == 'disconnected' && <div className="page" style={{ justifyContent: 'center' }}>
				<h1>SDBN3 TOKEN</h1>
				<div className='page-main'>
					<br /><br /><br />
					{!window.ethereum
						&& <a className='btn btn-primary' href="https://metamask.io/" target="_blank">Install metamask</a>
					}
					<br /><br /><br />
					<button className='btn btn-primary' onClick={() => dispatch(connect())}  > Connect wallet</button>
					<br /><br /><br />
				</div>
			</div>}

			{status != 'disconnected' && !userData?.userId && <div className="page" style={{ justifyContent: 'center' }}>
				<h1>SDBN3 TOKEN</h1>
				<div className='page-main'>
					<br /><br /><br />
					<div className="block block-value">
						<span className="title">Wallet address</span>
						<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
					</div>
					<br /><br /><br /><br />
					{<button className='btn btn-primary' onClick={() => dispatch(doLogin())}  > Login</button>}
					<br /><br /><br /><br />
					{<button className='btn btn-secondary btn-black' onClick={() => disconnectIt()}  > Disconnect wallet</button>}
					<br />
				</div>
			</div>}

			{status != 'disconnected' && userData?.userId && userData.smnetStatus !== 'ok' && <div className="page" style={{ justifyContent: 'center' }}>
				<h1>SDBN3 TOKEN</h1>
				<div className='page-main'>
					<br /><br /><br />
					<h3>No sunmoney.net login</h3>
					<div>{userData.smnetStatus}</div>
					<div>{JSON.stringify(userData.smnetData)}</div>
					<div className="block block-value">
						<span className="title">Wallet address</span>
						<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
					</div>

					<br /><br /><br /><br />
					{<button className='btn btn-primary' onClick={() => dispatch(doLogout())}  > Logout</button>}
					<br /><br /><br /><br />
					{<button className='btn btn-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>}
					<br />

				</div>
			</div>}

			{['query', 'ready', 'error'].indexOf(status) >= 0 && (userData?.userId) && (userData.smnetStatus === 'ok') &&

				<Router  >
					<Routes>

						{!depositTran ? <>
							<Route path="/" element={<Home />} />
							<Route path="/wallet" element={<Wallet />} />
							<Route path="/buy/bnb" element={<BuyBNB />} />
							<Route path="/buy/usdt" element={<BuyUSDT />} />
							<Route path="/buy/usdc" element={<BuyUSDC />} />
							<Route path="/buy/comission" element={<BuyComission />} />
							<Route path="/deposit" element={<Deposit />} />
						</>
							:
							<Route path="*" element={<Deposit />} />
						}
					</Routes>
				</Router>
			}


			<ToastMessages />
			<div className='popup'> {status}&nbsp;<button onClick={() => designIt(!design)}>design</button></div>

			<div className='logo-sunmoney'><img src={require("../images/sunmoney_logo.png")} /></div>
			<div className='logo-sdbn'><img src={require("../images/sdbn_logo.png")} /></div>
		</div>

	)
};


