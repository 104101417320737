import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAccountData } from '../../app/web3Slice';
import { BlockAmount, dispNum, normalizeInputNumber } from '../../common';
import { doApproveUSDT, doBuyUSDT } from './buySlice';




export default function BuyUSDT() {

	const accountData = useSelector(selectAccountData);


	const dispatch = useDispatch();

	const [inputValue, setInputValue] = useState(0);

	const [sdbn3Value, setSdbn3Value] = useState(0);
	const [usdtValue, setUsdtValue] = useState(0);

	const [inputKind, _setInputKind] = useState('sdbn3'); // sdbn3 | usdt

	const sdbn3InputRef = useRef();
	const usdtInputRef = useRef();


	const setInputKind = (kind) => {
		if (kind == 'sdbn3') {
			setInputValue(sdbn3Value.toFixed(2));
			window.requestAnimationFrame(() => {
				sdbn3InputRef.current.focus();
				sdbn3InputRef.current.select();
			})
		} else {
			setInputValue(usdtValue.toFixed(4));
			window.requestAnimationFrame(() => {
				usdtInputRef.current.focus();
				usdtInputRef.current.select();
			})
		}
		_setInputKind(kind);
	}

	useEffect(() => {
		let val = normalizeInputNumber(inputValue);
		if (inputKind === 'sdbn3') {
			setSdbn3Value(val);
			setUsdtValue(val * 0.015);
		} else {
			setUsdtValue(val);
			setSdbn3Value(val / 0.015)

		}
	}, [inputValue, inputKind]);



	return (
		<div className="page">
			<h1>Buy with USDT</h1>
			<div className="page-main">
				<div className="block block-value">
					<span className="title">USDT balance</span>
					<span className="value">
						<BlockAmount amount={accountData.balanceUSDT} /><span className="dim">USDT</span>
					</span>
				</div>
				<div className="block block-value">
					<span className="title">Approved USDT amount</span>
					<span className="value">
						<BlockAmount amount={accountData.approvedUSDT} /><span className="dim">USDT</span>
					</span>
				</div>




				{inputKind === 'sdbn3' && <>
					<div className="block block-value">
						<span className="title">Enter SDBN3 value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={sdbn3InputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">SDBN3&nbsp;</span>
						</span>

					</div>


					<div className="block block-value" onClick={() => setInputKind('usdt')}>
						<span className="title">Calculated USDT value</span>
						<span className="value">
							<BlockAmount amount={usdtValue} frac={4} /><span className="dim">USDT</span>
						</span>
					</div>
				</>}


				{inputKind === 'usdt' && <>
					<div className="block block-value" onClick={() => setInputKind('sdbn3')}>
						<span className="title">Calculated SDBN3 value</span>
						<span className="value">
							<BlockAmount amount={sdbn3Value} frac={2} /><span className="dim">SDBN</span>
						</span>
					</div>


					<div className="block block-value"  >
						<span className="title">Enter USDT value</span>
						<span className="value px-1">
							<input className="flat amount"
								ref={usdtInputRef}
								value={inputValue}
								onChange={(e) => setInputValue(e.currentTarget.value)}
							/>
							<span className="dim">USDT&nbsp;</span>

						</span>

					</div>
				</>}




				<div className="block block-value">
					<span className="title">Buy SDBN3 with USDT</span>
					<span className="value px-1">
						<button type="button"
							className={'btn btn-light btn-sm'}
							onClick={() => dispatch(doApproveUSDT(normalizeInputNumber(usdtValue)))} >
							Approve
						</button>
						<button type="button"
							className={'btn btn-primary btn-sm'}
							onClick={() => dispatch(doBuyUSDT(normalizeInputNumber(usdtValue)))} >
							Buy
						</button>
					</span>
				</div>

			</div>
			<div className="block"><Link className="btn btn-secondary" to='/'>Home</Link></div>

		</div>
	)
};


